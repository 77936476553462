import React from "react"
import get from 'lodash/get'
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import HeaderSection from '../components/Careers/HeaderSection/HeaderSection'
import Team from '../components/Careers/Team/Team'
import CareersList from '../components/Careers/CareersList/CareersList'
import QuoteScrollBox from '../components/General/QuoteScrollBox/QuoteScrollBox'

const ContactPage = props => {
  const careers = get(props, 'data.careers.nodes',[])
  const team = get(props, 'data.team.nodes',[])
  const seoData = get(props, 'data.seo.nodes[0].seo',[])

  let data = {
    title: seoData.title,
    description: seoData.description,
    lang: 'en',
    image: seoData.social.facebook.image && seoData.social.facebook.image.url,
    imageTwitter: seoData.social.twitter.image && seoData.social.twitter.image.url,
    robots: seoData.advanced.robots && seoData.advanced.robots
  }
  
  // slugs for translation
  const slugs = {
    slugNl: props.data.translatedUrl.nodes[0].slug,
    slugEn: props.data.pageData.nodes[0].slug,
  }
  
  return(
    <Layout form={true} data={props.data.site} location={props.location} slugs={slugs}>
      <SEO {...data} />
      <HeaderSection/>
      <CareersList careersData={careers}/>
      {/*<QuoteScrollBox quoteText1={["Wanna put some extra"]} quoteText2= {[<strong>'super'</strong>," in Superkraft?"]} />*/}
      {/*<Team data={team} />*/}
    </Layout>
  )
}

export default ContactPage

export const query = graphql`
  {
    site{
      siteMetadata{
        languages {
          defaultLangKey
          langs
        }
      }
    }
    pageData: allCraftCareersCareersEntry(filter: {siteId: {eq:2}}){
      nodes{
        slug
        language
      }
    }
    translatedUrl: allCraftCareersCareersEntry(filter: {siteId: {eq:2}}){
      nodes{
        slug
      }
    }
    seo:allCraftCareersCareersEntry(filter: {siteId: {eq:2}}){
      nodes {
        title
        seo {
          title
          description
          social {
            facebook {
              image {
                url
              }
            }
            twitter {
              image {
                url
              }
            }
          }
          advanced {
            robots
          }
        }
      }
    }
    careers: allCraftCareerCarreerEntry( filter: {onlylandingpage: {eq: false}, siteId: {eq: 1}}) {
      nodes {
        title
        id
        slug
        onlylandingpage
        careerIntro
        language
        siteId
        careerMainImage {
          url
        }
        careerTextBlock1
        careerTextBlock2
      }
    }
  }
`