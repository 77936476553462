import React from 'react'
import styled from 'styled-components'
import { colors,media } from '../../../lib/style-utils'
import { Controller, Scene } from 'react-scrollmagic';
import gsap from 'gsap'
import { Tween } from 'react-gsap'
import { CustomEase } from "gsap/CustomEase";

import ActTable from '../../../styles/ActTable'
import ActCell from '../../../styles/ActCell'

import RevealOpacity from '../../Animation/RevealOpacity'

gsap.registerPlugin(CustomEase);

/* QuoteScrollBox s */
const QuoteScrollBoxWrap = styled.div`
    text-align: center;
    color: ${colors.white};
    width: 100vw;
    height: 75vh;
    max-height: 20rem;
    overflow: hidden;
    position: relative;
    h1{
        font-size: 1.7rem;
        line-height: 3rem;
        font-weight: bold;
        position: relative;
        top: -1rem;
    }
    span{
        position: absolute;
        width: 200vw;
    }
    span.scrollBox1{
        left: -70%;
    }
    span.scrollBox2{
        right: -70%;
        padding-bottom: 10rem;
    }
    strong{
        color: ${colors.dark_grey}
    }
    ${media.phoneXL`
        max-height: 20rem;
        h1{
            font-size: 2.3rem;
            line-height: 3rem;
            top: -1.2rem;
        }
    `}
    ${media.phablet`
        max-height: 25rem;
        h1{
            font-size: 2.5rem;
            line-height: 3.6rem;
            top: -1rem;
        }
    `}
    ${media.tablet`
        max-height: 30rem;
        h1{
            font-size: 3.2rem;
            line-height: 4rem;
            top: -1.2rem;
        }
    `}
    ${media.desktop`
        max-height: 100vh;
        h1{
            font-size: 4rem;
            line-height: 6rem;
            top: -3rem;
        }
    `}
    ${media.midDesktop`
        max-height: 100vh;
        h1{
            font-size: 5rem;
            line-height: 9rem;
            top: -4.5rem
        }
    `}
    ${media.lgDesktop`
        max-height: 100vh;
        h1{
            font-size: 6rem;
            line-height: 10rem;
            top: -5rem
        }
    `}
`

const BackgroundGradient = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: ${colors.gradient};
`

const QuoteScrollBox = ( props ) => {
    CustomEase.create("customEase", "M0,0 C0.406,0 0.531,0.4 0.565,0.528 0.606,0.686 0.694,1 1,1 ");
    return(
        <QuoteScrollBoxWrap>
            <BackgroundGradient />
            <div id="trigger"></div>
            <ActTable>
                <ActCell>
                    <RevealOpacity>
                        <h1>
                        <Controller>
                                <Scene 
                                    triggerElement="#trigger"
                                    duration="100%"
                                >
                                    {
                                        progress => (
                                            <Tween
                                                to={{
                                                    translateX: "100vw",
                                                    ease: "customEase"
                                                }}
                                                ease = "customEase"
                                                totalProgress={progress}
                                                paused
                                            >
                                                <span className="scrollBox1">
                                                    { props.quoteText1 }
                                                </span>
                                            </Tween>
                                        )
                                    }
                                </Scene>
                            </Controller>
                            <br />
                            <Controller>
                                <Scene 
                                    triggerElement="#trigger"
                                    duration="100%"
                                >
                                    {
                                        progress => (
                                            <Tween
                                                to={{
                                                    translateX: "-100vw",
                                                    ease: "customEase"
                                                }}
                                                ease = "customEase"
                                                totalProgress={progress}
                                                paused
                                            >
                                                <span className="scrollBox2">
                                                    { props.quoteText2 }
                                                </span>
                                            </Tween>
                                        )
                                    }
                                </Scene>
                            </Controller>
                        </h1>
                    </RevealOpacity>
                </ActCell>
            </ActTable>
        </QuoteScrollBoxWrap>
    )
}

export default QuoteScrollBox